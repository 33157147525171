import React from 'react';
import { connect } from 'react-redux';

import { IntlProvider } from 'react-intl';

import ProductsV2 from './ProductsV2';

import translations from '../../../translations.json';

const mapStateToProps = (state) => ({});

class ProductsApp extends React.Component {
  render() {
    const { props } = this;
    const locale = 'en';

    return (
      <IntlProvider key={locale} locale={locale} messages={translations[locale]}>
        <ProductsV2 />
      </IntlProvider>
    );
  }
}

export default connect(mapStateToProps)(ProductsApp);
