import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import locationData from './src/shops/reducers/locationData';
import userSignedIn from './src/ui/products/reducers/userSignedIn';
import userStoreAdmin from './src/ui/products/reducers/userStoreAdmin';

import ProductsApp from './src/ui/products/components/ProductsApp';

const rootReducers = combineReducers({ locationData, userSignedIn, userStoreAdmin });
const store = createStore(rootReducers, applyMiddleware(thunk));

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Provider store={store}>
      <ProductsApp />
    </Provider>,
    document.getElementById('products-app'),
  );
});
