import axios from 'axios';

export function getProducts(params = {}) {
  return axios.get('/api/v1/products', { params }, {});
}

export function getProduct(uuid) {
  return axios.get(`/api/v1/products/${uuid}`, {}, {});
}

export function getStoreLocationProducts(storeLocationUUID, params = {}) {
  return axios.get(`/api/v1/store_locations/${storeLocationUUID}/products`,  { params }, {});
}
